<script>
import DefaultEstoreSummary from '/~/extensions/estore/components/estore-summary.vue'
import ProductTotal from '/~/extensions/giftcards/templates/australiapost/components/product/total/product-total.vue'
import EwProductType from '/~/templates/australiapost/components/ewallet/product/ew-product-type.vue'

export default {
  components: {
    ProductTotal,
    EwProductType,
  },
  extends: DefaultEstoreSummary,
}
</script>

<template>
  <div class="sm:pb-20">
    <div class="px-5 text-center md:px-0 md:text-left">
      <h2
        v-if="retailer"
        class="text-2xl font-bold sm:text-3xl"
        data-cy="estore-retailer"
      >
        {{ retailer }}
      </h2>
      <h2
        class="text-2xl font-normal text-eonx-neutral-800 sm:text-3xl"
        data-test="header"
        data-cy="estore-name"
      >
        {{ name }}
      </h2>
      <ew-product-type
        v-if="!ui.mobile"
        :physical="true"
        :gift-card="false"
        class="mt-2.5 text-eonx-neutral-600"
      />
    </div>

    <div class="my-6 flex items-center justify-between bg-light px-5 py-2.5">
      <div class="min-w-[120px] sm:w-2/3" data-cy="calculation-counter">
        <base-counter
          v-model="quantity"
          :min="1"
          :max="quantityMax"
          data-test="quantity"
          small
        />
      </div>
      <product-total :amount="total" />
    </div>
    <div class="mt-[30px] flex items-center justify-end" data-test="control">
      <base-button
        v-if="isAddToCartEnabled"
        ref="addToCardButton"
        :disabled="
          adding || isAddButtonDisabled || isCheckingIdentityVerification
        "
        full-width="mobile"
        data-test="addToCard"
        @click="withAccessCheck({ callback: addToCart })"
      >
        {{
          adding || isCheckingIdentityVerification ? 'Loading' : 'Add to cart'
        }}
      </base-button>
    </div>
  </div>
</template>
